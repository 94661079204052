import { css } from 'styled-components'

import avenirNextDemiWoff from './assets/fonts/AvenirNextW01-Demi.woff'
import avenirNextRegularWoff from './assets/fonts/AvenirNextW01-Regular.woff'
import landRoverWebWoff from './assets/fonts/LandRoverWeb-Bold.woff'
import UKNumberPlateEot from './assets/fonts/uknumberplate/UKNumberPlate.eot'
import UKNumberPlateWoff2 from './assets/fonts/uknumberplate/UKNumberPlate.woff2'

const fonts = css`
  @font-face {
    font-family: 'Land Rover Web';
    src: url('${landRoverWebWoff as never}') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Avenir Next';
    src: url('${avenirNextRegularWoff as never}') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Avenir Next';
    src: url('${avenirNextDemiWoff as never}') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'UKLicencePlate';
    src: url('${UKNumberPlateEot as never}') format('eot'),
      url('${UKNumberPlateEot as never}') format('eot');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'UKLicencePlate';
    src: url('${UKNumberPlateWoff2 as never}') format('woff2'),
      url('${UKNumberPlateWoff2 as never}') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }
`

export default fonts
